<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <div class="card">
        <Steps :model="items" :readonly="true" />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export default {
  name: "VideoSteps",
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const { lang, processId } = route.params;
    return {
      items: [
        {
          label: t("video.common.step_upload"),
          to: `/${lang}/panel/process/steps/upload`,
        },
        {
          label: t("video.common.step_verify"),
          to: `/${lang}/panel/process/steps/verify`,
        },
        {
          label: t("video.common.step_payment"),
          to: `/${lang}/panel/process/steps/payment`,
        },
        {
          label: t("video.common.step_finished"),
          to: `/${lang}/panel/process/steps/finished/${processId}`,
        },
      ],
    };
  },
};
</script>
